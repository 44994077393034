import axios from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';

function FileViewModal(props) {
  const { company, file, hideModal } = props;
  const [waveInfo, setWaveInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth();
  
  useEffect(() => {
    getWaveInfo(company, file);
    setShowModal(true);
  }, []);

  function getWaveInfo(company, file) {
    const qs = queryString.stringify({ company, file });
    try {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_PREFIX}/bonzai/waves/getExcelContent?${qs}`,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${user.token}` }
      })
        .then(
          response => {
            if (response.data.results && response.data.results[0] && response.data.results[0].result) {
              let orders = response.data.results[0].result.orders;
              let totalOrders = Object.keys(orders).length;
              setWaveInfo({ company, file, orders });
            }else{
              setWaveInfo([]);
            }
          },
          error => {
            console.log('error...', error);
          }
        )
        .catch(error => {
          console.log('error:', error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={`modal fade ${showModal ? ' show d-block ' : ''}`}  data-bs-backdrop="static"  data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel"  >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title text-bold mr-2" id="staticBackdropLabel" >
              <button className="btn btn-blue mx-2 px-2">
                <img src="/images/show.svg" alt="" />
              </button>
              File View
            </h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setShowModal(false); hideModal()}}></button>
          </div>
          <div className="modal-body overflow-scroll">
            <div style={{ height: '90vh' }}>
              <table className="table table-responsive table-bordered">
                <thead>
                  <tr>
                    <th className="bg-gray4">Creation_Date</th>
                    <th className="bg-gray4">Dest_Storage_Bin</th>
                    <th className="bg-gray4">Dest_Storage_Type</th>
                    <th className="bg-gray4">Group</th>
                    <th className="bg-gray4">Material</th>
                    <th className="bg-gray4">Material_Description</th>
                    <th className="bg-gray4">Source_Storage_Bin</th>
                    <th className="bg-gray4">Source_Storage_Type</th>
                    <th className="bg-gray4">Source_target_qty</th>
                    <th className="bg-gray4">Storage_location</th>
                    <th className="bg-gray4">Transfer_Order_Number</th>
                    <th className="bg-gray4">User</th>
                  </tr>
                </thead>
                <tbody className="overflow-scroll" style={{ height: '90vh' }}>
                { waveInfo === null ? (
                    <tr> <td colSpan="12" className="mt-4 text-center text-gray8">  Caricamento...</td></tr>
                  ) : waveInfo.length === 0 ? (
                    <tr> <td colSpan="12" className="mt-4 text-center text-gray8">  Nessun dato trovato.</td></tr>
                  ) : (
                    waveInfo.orders.map((row, index) => {
                      return (
                        <tr key={`${index}`}>
                          <td>{row.Creation_Date}</td>
                          <td>{row.Dest_Storage_Bin}</td>
                          <td>{row.Dest_Storage_Type}</td>
                          <td>{row.Group}</td>
                          <td>{row.Material}</td>
                          <td>{row.Material_Description}</td>
                          <td>{row.Source_Storage_Bin}</td>
                          <td>{row.Source_Storage_Type}</td>
                          <td>{row.Source_target_qty}</td>
                          <td>{row.Storage_location}</td>
                          <td>{row.Transfer_Order_Number}</td>
                          <td>{row.User}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileViewModal;
