import { Fragment, useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import moment from "moment/moment";
import FileViewModal from "./FileViewModal";
import DeleteModal from "./DeleteModal";
import useAuth from "../hooks/useAuth";
import ErrorModal from "./ErrorModal";




function Upload() {
  const [errorModalMessage, setErrorModalMessage] = useState(null);
  const [uploadWaveFormData, setUploadWaveFormData] = useState(null);
  const [generateReqSent, setGenerateReqSent] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadWaveId, setuploadWaveId] = useState(null);
  const [showFileRowCount, setShowFileRowCount] = useState(false);
  const [waves, setWaves] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showVisualizeFileModal, setShowVisualizeFileModal] = useState(false);
  const [visualizeFileName, setVisualizeFileName] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteFileName, setDeleteFileName] = useState(null);
  const fileInputRef = useRef(null);
  const { waveType } = useParams();
  const navigate = useNavigate();
  const { user, signout } = useAuth();

  const cards = {

    "arputtolight" : [
      {
        img: "/images/assignment-img.svg",
        title: "Wave",
        buttonName: "SCOPRI",
        link: `/waves/${waveType}`
      },
      {
        img: "/images/report-img.svg",
        title: <>Gestione <br/> Utenti</>,
        buttonName: "SCOPRI",
        link: `/users`
      }
    ],
    "arpicking":
      [
        {
          img: "/images/assignment-img.svg",
          title: "Wave",
          buttonName: "SCOPRI",
          link: `/waves/${waveType}`
        },
        {
          img: "/images/report-img.svg",
          title: <>Gestione <br/> Utenti</>,
          buttonName: "SCOPRI",
          link: `/users`
        },
        {
          img: "/images/scaffale_2.png",
          title: <>Gestione <br/> Scaffali</>,
          buttonName: "SCOPRI",
          link: `http://bonzaishelves.vection-technologies.com:8502/`
        },
      ]
  }
  
 

  function logout() {
    signout(() => {
      navigate("/");
    });
  }

  function resetUpload() {
    setErrorModalMessage(null);
    setUploadWaveFormData(null);
    setGenerateReqSent(false);
    setFileSelected(false);
    setFileUploaded(false);
    setShowFileRowCount(false);
    setErrorMessage(null);
  }
  useEffect(() => {
    getWaves();
  }, []);

  function handleUploadChange(e) {
    e.preventDefault();
    setErrorMessage(false);
    setShowFileRowCount(false);
    setFileSelected(false);

    if (fileInputRef) {
      const formData = new FormData();
      const fileName = "wave " + Math.floor(Math.random() * 1010) + ".xlsx";
      const file = e.target.files[0];
      formData.append("file", file);
      let data = {
        company: user ? user.company : "",
        productID: user ? user.company : "",
        wavetype: waveType,
        filename: fileName,
      };
      setFileSelected(true);
      setUploadWaveFormData({ data, formData });
    }
  }

  function handleGeneraClick() {
    setGenerateReqSent(true);
    const { data, formData } = uploadWaveFormData;
    if (!data || !formData) return true;
    const qs = queryString.stringify(data);

    try {
      axios
        .post(`${process.env.REACT_APP_API_PREFIX}/bonzai/waves/uploadWave?${qs}`, formData, {
          headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${user.token}` },
          "Accept": "application/json",
        })
        .then(
          (response) => response.data
        ).then((data) => {
          const firstResult = data.results[0];
          if (firstResult['status'] === "ok") {
            let waveId = firstResult.result.id;
            setuploadWaveId(waveId.substring(waveId.indexOf(":") + 1, waveId.length));
            setFileUploaded(true);
            setTimeout(() => {
              navigate(`/waves/${waveType}`);
            }, 1000);
          } else if (firstResult['status'] === "error") {
            setErrorModalMessage(firstResult['message'] || "Something went wrong. Please try again.");
          } else {
            /* let hasError = (item) => item && item.bod && item.body.status === ("exception" || "error");
            data.results.some(hasError);
            console.log(data.results)
            if (!hasError) {
              setFileUploaded(true);
              setTimeout(() => {
                navigate(`/waves/${waveType}`);
              }, 1000);
            } else {
              setErrorModalMessage("Non è stato possibile caricare una o più wave. Verificare il file excel e riprovare!");
            } */


            if (data.results.some(item => item && item.status === 'ok')) {
              setFileUploaded(true);
              setTimeout(() => {
                navigate(`/waves/${waveType}`);
              }, 1000);
            } else {
              setErrorModalMessage("Tutte le wave del file appena caricato risultano gia' presenti nel sistema");
            }

            /* setFileUploaded(true)
            setTimeout(() => {
              navigate(`/waves/${waveType}`);
            }, 1000); */
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  function getWaves() {
    const qs = queryString.stringify({ company: user.company, wavetype: waveType, hasFile: true });
    try {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_PREFIX}/resources/getFilesList?${qs}`,
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then(
          (response) => {
            if (response && response.data && response.data.result) {
              const { files } = response.data.result;
              setWaves(files);
            } else {
              setWaves([]);
            }
          },
          (error) => {
            if (error.response.status === 401) {
              // alert("Token Expired.Please login again.");
              logout();
            }
          }
        )
        .catch((error) => {
          console.log("error:", error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function downloadFile(filename) {
    const qs = queryString.stringify({ filename });
    try {
      const req = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        url: `${process.env.REACT_APP_API_PREFIX}/resources/downloadFile?${qs}`,
        responseType: "blob",
      });
      var blob = new Blob([req.data], {
        type: req.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } catch (error) { }
  }

  return (
    <div className="upload">
      <div className="upload_page position-absolute top-0 w-100" style={{ height: "28rem", zIndex: -1 }}></div>
      <div className="container d-flex justify-content-center  pt-5">
        <div className="w-75">
          <div className="entry-heading pb-4">
            <h2 className="text-white fw-bold d-flex align-items-center" >
              Web&nbsp;<span className="img-l"> Cloud </span>&nbsp;Server
            </h2>
          </div>

          {errorModalMessage && (
            <ErrorModal
              message={errorModalMessage}
              _onClose={() => {
                setErrorModalMessage(null);
                resetUpload();
              }}
            />
          )}

          {errorMessage && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>Errore!</strong> {errorMessage}
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          )}

          {/* Select File */}
          {!fileSelected && (
            <Fragment key="file-uploader">
              <div
                className="w-100 rounded-5 mb-4 d-flex justify-content-center align-items-center position-relative"
                style={{ height: "20rem", backgroundColor: "#f1f8fd", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
              >
                <div className="position-absolute start-100 ms-4">
                  <button type="button" className="btn btn-blue my-2 px-2" data-bs-toggle="modal" data-bs-target="#fileHistoryModal">
                    <img src="/images/upload.svg" alt="" />
                  </button>
                </div>
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    fileInputRef && fileInputRef.current.click();
                  }}
                >
                  <img src="/images/upload-file.svg" />
                  <div className="text-purple2 pt-3 fw-bold">Carica Nuovo File</div>
                </div>
                <input
                  type="file"
                  name="file1"
                  onChange={(e) => {
                    handleUploadChange(e);
                  }}
                  ref={fileInputRef}
                  className="d-none"
                  readOnly
                />
              </div>
            </Fragment>
          )}

          {fileSelected ? (
            <Fragment>
              {fileUploaded && !showFileRowCount ? (
                <div
                  className="w-100 rounded-5 mb-4 d-flex justify-content-center align-items-center position-relative"
                  style={{
                    height: "10rem",
                    backgroundColor: "#f1f8fd",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    backgroundImage: "linear-gradient(90deg, #2C589F 0%, #70BCD8 100%)",
                  }}
                >
                  <div className="position-absolute start-100 ms-4">
                    <button className="btn btn-blue my-2 px-2" >
                      <img src="/images/show.svg" alt="" onClick={() => {

                      }} />
                    </button>
                    <button type="button" className="btn btn-blue my-2 px-2" data-bs-toggle="modal" data-bs-target="#fileHistoryModal">
                      <img src="/images/upload.svg" alt="" />
                    </button>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src="/images/uploaded.svg" />
                    <div className="text-gray2 pt-3">File caricato</div>
                  </div>
                </div>
              ) : (
                <div
                  className="w-100 rounded-5 mb-4 d-flex justify-content-center align-items-center position-relative"
                  style={{
                    height: "10rem",
                    backgroundColor: "#f1f8fd",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <div className="position-absolute start-100 ms-4">
                    {/* <button className="btn btn-blue my-2 px-2" data-bs-toggle="modal" data-bs-target="#fileViewModal">
                      <img src="/images/show.svg" alt="" />
                    </button> */}
                    <button type="button" className="btn btn-blue my-2 px-2" data-bs-toggle="modal" data-bs-target="#fileHistoryModal">
                      <img src="/images/upload.svg" alt="" />
                    </button>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(0);
                    }}
                  >
                    <img src="/images/upload-file.svg" />
                    <div className="text-purple2 pt-3 fw-bold">Carica Nuovo File</div>
                  </div>
                </div>
              )}

              <div
                className="w-100 bg-white rounded-5 d-flex flex-column justify-content-center align-items-center position-relative py-3"
                style={{
                  height: "18rem",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                {fileUploaded && !showFileRowCount ? (
                  <Fragment>
                    <div className="text-purple2 fs-1 fw-bold mb-3">Wave generate con successo!</div>
                    <div className="d-flex w-100 justify-content-around align-items-center mt-3 position-relative">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="text-purple2 fs-4 fw-bold my-2">
                          <img src="/images/success.svg" alt="" />
                        </div>
                        <p className="text-center">
                          Controlla la sezione <strong className="text-bold">“Stato Evasione Wave”</strong> <br />
                          per scoprire lo stato delle singole missioni!
                        </p>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="text-purple2 fs-1 fw-bold mb-3">Genera l'assegnazione missioni!</div>
                    <div className="position-absolute top-100 start-50 translate-middle">
                      <button
                        type="submit"
                        className={`btn btn-blue fw-bold px-5 py-2 rounded-4 fs-4 `}
                        onClick={() => {
                          if (!fileUploaded) {
                            handleGeneraClick();
                          }
                        }}
                        disabled={generateReqSent ? true : false}
                      >
                        {generateReqSent && (
                          <div className="spinner-border " role="status" style={{ width: "1.3rem", height: "1.3rem" }}>
                            <span className="sr-only"></span>{" "}
                          </div>
                        )}
                        GENERA
                      </button>
                    </div>
                  </Fragment>
                )}
              </div>
            </Fragment>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/*------------------------------------------------------------
       * FOOTER Links
       ------------------------------------------------------------ */}
      <div className="container-upload ">
        <div className="w-100 pb-5">
          <div className="text-purple2 fw-bold fs-1 my-3">Dettaglio Wave</div>
          <div className="row">
            {cards[waveType].map((card, index) => {
              return (
                <div className="col-6 position-relative mb-5" key={index}>
                  <div
                    className="w-100 p-4 d-flex justify-content-center align-items-center rounded-4"
                    style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                  >
                    <img src={card.img} alt='' />
                    <h1 className="text-purple2">
                      {card.title}
                    </h1>
                    <div className="position-absolute top-100 end-0 translate-middle">
                      <Link to={card.link} className="btn btn-blue fw-bold rounded-4">
                        {card.buttonName}
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })} 
          </div>
        </div>
      </div>

      <div className="top_left_circle"></div>
      <div className="right_center_circle"></div>

      {/* ------------------------------------------------------------
       *File History Modal
       --------------------------------------------------------------- */}
      <div className="modal fade" id="fileHistoryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl modal-custom">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title text-bold mr-2" id="staticBackdropLabel">
                <button className="btn btn-blue mx-2 px-2">
                  <img src="/images/upload.svg" alt="" />
                </button>
                Storico File
              </h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <table className="table w-100 fileHistory">
                <tbody>
                  {waves === null ? (
                    <p className="mt-4 text-center text-gray8 h4">Caricamento...</p>
                  ) : waves.length === 0 ? (
                    <p className="mt-4 text-center text-gray8 h4">Nessun dato trovato.</p>
                  ) : (
                    waves.map((row, index) => {
                      let date1 = row.timestamp.replace("Z", "").replace("T", "");
                        date1.replace("Z", "").replace("T", "");
                      return (
                        <tr className="text-center align-middle" key={`file-history-${index}`}>
                          <td>
                            <img className="border-0 mx-1" src="/images/file.svg" alt="" />
                          </td>
                          <td width="40%">
                            <span className="btn rounded-pill py-3 text-blue3 w-100 text-lowercase text-capitalize" style={{ backgroundColor: "#EBEFF0" }}>
                              {row.name}
                            </span>
                          </td>
                          <td width="30%">
                            <span className="mx-3 text-blue3">
                              {date1 ? moment(date1, "YYYYMMDDHH:mm:ss.SSS").format("LLL") : ""}
                            </span>
                          </td>
                          <td className="align-items-center w-100">
                            <div className="d-flex" style={{ justifyContent: "space-around", display: "flex" }}>
                              <button
                                className="btn btn-green px-4 py-3 rounded-pill w-100 mx-1"
                                onClick={() => {
                                  setVisualizeFileName(row.name);
                                  setShowVisualizeFileModal(true);
                                }}
                              >
                                Visualizza
                              </button>
                              <button
                                className="btn btn-purple px-4 py-3 rounded-pill w-100 mx-1"
                                onClick={() => {
                                  downloadFile(row.name);
                                }}
                              >
                                Scarica
                              </button>
                              <button
                                className="btn btn-pink px-4 py-3 rounded-pill w-100 mx-1"
                                onClick={() => {
                                  setDeleteFileName(row.name);
                                  setShowDeleteModal(true);
                                }}
                              >
                                Elimina
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {showDeleteModal && (
          <DeleteModal
            file={deleteFileName}
            _onSuccess={() => {
              setDeleteFileName(null);
              setShowDeleteModal(false);
              getWaves();
            }}
            _onCancel={() => {
              setDeleteFileName(null);
              setShowDeleteModal(false);
            }}
          />
        )}
        {showVisualizeFileModal && (
          <FileViewModal
            company={user.company ? user.company : ''}
            file={visualizeFileName}
            hideModal={() => {
              setShowVisualizeFileModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Upload;
