import { Fragment, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import UserQRCode from './UserQRCode';

function CreateUser() {
  const [activeStep, setActiveStep] = useState(1);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const { user } = useAuth();
  const [inputs, setInputs] = useState({
    'company': user.company,
    'user': '',
    'password': '',
    'role': 'operator',
    'name': '',
    'surname': '',
    'email': ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [downloadPDF, setDownloadPDF] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  function handleStep1Submit(e) {
    e.preventDefault();
    setErrorMessage(null);
    if (inputs.user === null || inputs.password === null || confirmPassword === null) {
      setErrorMessage('Please enter details');
      return true;
    }
    if (inputs.password !== confirmPassword) {
      setErrorMessage('Password and Confirm password does not match.');
      return true;
    }
    setActiveStep(2);
  }

  function handleStep2Submit(e) {
    e.preventDefault();
    if (submitted) return true;
    setSubmitted(true);
    setErrorMessage(null);
    let formData = {
      ...inputs,
      'name': inputs.user,
      'surname': inputs.user,
      'email': inputs.user
    };
    axios
      .post(
        process.env.REACT_APP_API_PREFIX + '/users/createUser',
        { ...formData },
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }
        }
      )
      .then(response => {
        if (response && response.data && response.data.status === 'ok') {
          navigate('/users', { state: location.state && location.state.selection ? location.state.selection : '' });
        } else {
          setErrorMessage(response.data.message || 'something went wrong!');
        }
      });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  return (
    <main className="create_user_page">
      <section className="banner-display-1 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-9">
              <div className="entry-heading">
                <h2 className="  text-white fw-bold d-flex align-items-center" >
                  Web&nbsp;<span className="img-l"> Cloud </span>&nbsp;Server
                </h2>
                <p className="fs-14 fw-bold text-white">
                  Scegli la procedura per entrare nel web Cloud Server per poter generare le missioni
                  <br /> inerenti alla giornata!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mission-details  position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-9">
              <div className="mission_success bottom_shadow round  position-relative">
                <div
                  className="cp-40 cpy-30 bg-white rounded-6 text-center w-100"
                  style={{
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                  }}
                >
                  <div>
                    <h2 className="fs-34 fw-semibold text-content cmb-0 text-blue3 pt-5">Aggiungi Utente</h2>
                    <h3 className="fs-28 fw-semibold text-black pb-5">Compila e registra ora un nuovo utente</h3>
                  </div>

                  {activeStep === 1 && (
                    <form
                      method="post"
                      onSubmit={(e) => {
                        handleStep1Submit(e);
                      }}
                    >
                      <div className="container bg-gray3 d-flex justify-content-center align-items-center add-user-wrapper">
                        <div className="col-8 py-5">
                          {errorMessage && (
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                              <strong>Error!</strong> {errorMessage}
                              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                          )}
                          <div className="my-5">
                            <input
                              type="text"
                              className="form-control form-control-lg text-gray8 p-2 px-4 rounded-5"
                              id="usernameInput"
                              name="user"
                              aria-describedby="usernameInput"
                              placeholder="Username"
                              style={{ fontSize: '1rem' }}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="my-5">
                            <input
                              type="password"
                              name="password"
                              className="form-control form-control-lg text-gray8 p-2 px-4 rounded-5"
                              id="exampleInputPassword1"
                              placeholder="Password"
                              style={{ fontSize: '1rem' }}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="my-5">
                            <input
                              type="password"
                              name="confirmPassword"
                              className="form-control form-control-lg text-gray8 p-2 px-4 rounded-5"
                              id="exampleInputPassword2"
                              placeholder="Conferma Password"
                              style={{ fontSize: '1rem' }}
                              onChange={e => {
                                setConfirmPassword(e.target.value);
                              }}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className='footerButtons py-5'>
                        <Link
                          to="/users"
                          className="btn btn-blue2 fw-bold py-3  rounded-pill border-2 position-relative"

                        >
                          <img className="" src="/images/back.svg" alt="" />
                          INDIETRO
                        </Link>
                        <button type="submit" className="btn btn-green2  text-white fw-bold py-3 rounded-pill">
                              CONTINUA
                        </button>
                      </div>

                      
                    </form>
                  )}

                  {activeStep === 2 && (
                    <form
                      method="post"
                      onSubmit={(e) => {
                        handleStep2Submit(e);
                      }}
                    >
                      <div className="container bg-gray3 d-flex justify-content-center align-items-center add-user-wrapper">
                        <div className="col-8 py-5">
                          {errorMessage && (
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                              <strong>Error!</strong> {errorMessage}
                              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                          )}
                          {downloadPDF ? (
                            <>
                              <UserQRCode
                                key={Math.random()}
                                username={inputs.user}
                                password={inputs.password}
                                qrCodeText={downloadPDF}
                                successCallback={() => {
                                  setDownloadPDF(null);
                                }}
                              />
                            </>
                          ) : null}

                          <h4>
                            <b>Download QRCode</b>
                          </h4>
                          <img
                            className="border-0 m-2"
                            src="/images/qrcode-black.svg"
                            alt=""
                            style={{ width: '4.5rem', cursor: 'pointer' }}
                            onClick={() => {
                              setDownloadPDF(true);
                            }}
                          />
                        </div>
                      </div>

                     {/*  <div className="row py-5 justify-content-center align-items-center btn-group d-flex">
                        <div className="col-6">
                          <div className="d-flex align-items-center  w-100">
                            <button
                              onClick={() => {
                                setActiveStep(1);
                              }}
                              className="btn btn-blue2 fw-bold px-5 py-3 rounded-pill border-2 mx-3 position-relative w-50"
                              to="/users"
                            >
                              <img className="position-absolute start-0 ml-1" src="/images/back.svg" alt="" style={{ marginLeft: '1rem' }} />
                              INDIETRO
                            </button>

                            <button type="submit" className="btn btn-green2 fw-bold px-5 py-3 rounded-pill mx-3 w-50">
                              CONFERMA
                            </button>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="row py-5 justify-content-center align-items-center btn-group d-flex">
                        <div className="col-6">
                          <div className="d-flex align-items-center  w-100">
                            <button
                              onClick={() => {
                                setActiveStep(1);
                              }}
                              className="btn btn-blue2 fw-bold px-5 py-3 rounded-pill border-2 mx-3 position-relative w-50"
                              to="/users"
                            >
                              <img className="position-absolute start-0 ml-1" src="/images/back.svg" alt="" style={{ marginLeft: '1rem' }} />
                              INDIETRO
                            </button>

                            <button type="submit" className="btn btn-green2 fw-bold px-5 py-3 rounded-pill mx-3 w-50">
                              CONFERMA
                            </button>
                          </div>
                        </div>
                      </div> */}

                      <div className='footerButtons py-5'>
                        <button
                         onClick={() => {
                          setActiveStep(1);
                        }}
                          to="/users"
                          className="btn btn-blue2 fw-bold py-3 px-4 rounded-pill border-2 position-relative"

                        >
                          <img className="" src="/images/back.svg" alt="" />
                          INDIETRO
                        </button>
                        <button type="submit" className="btn btn-green2  text-white fw-bold py-3 rounded-pill">
                              CONTINUA
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mb-5 pb-5"></div>
    </main>
  );
}

export default CreateUser;
