import axios from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';

function DeleteModal(props) {
  const { file, _onSuccess, _onCancel } = props;
  const [waveInfo, setWaveInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    setShowModal(true);
  }, []);

  function handleDeleteFile() {
    const qs = queryString.stringify({ 'filename': props.file });
    try {
      axios
        .delete(`${process.env.REACT_APP_API_PREFIX}/resources/deleteFile?${qs}`, {
          headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${user.token}` }
        })
        .then(
          success => {
            props._onSuccess();
            setShowModal(false);
          },
          error => {
            _onCancel();
            const resObj = error.response.data;
          }
        );
    } catch (error) {
        _onCancel();
      console.log(error);
    }
  }

  return (
    <div
      className={`modal fade ${showModal ? ' show d-block ' : ''}`}
      id="fileViewModal1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-custom">
        <div className="modal-content">
          <div className="modal-body text-center text-purple text-bold  m-5">
            <h2>Sei sicuro di volercancellare il file? </h2>
            <p>Perderai anche i Record legati a quel file</p>
            <div className="align-items-center w-100">
              <div className="d-flex" style={{ justifyContent: 'space-around', display: 'flex' }}>
                <button className="btn btn-blue border-0 bg-gradient1 text-gray6 fw-bold px-3 rounded-4 w-100 mx-3" onClick={() => {
                  handleDeleteFile();
                  }}>CANCELLA</button>
                <button
                  className="btn btn-blue border-0 bg-gradient2 text-gray6 fw-bold px-3 rounded-4 w-100 mx-3"
                  onClick={() => {
                    _onCancel();
                    
                  }}
                >
                  INDIETRO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
