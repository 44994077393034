import axios from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import DeleteUserModal from './DeleteUserModal';

function Users() {
  const [users, setUsers] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const [activeUsername, setActiveUsername] = useState(null);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signout } = useAuth();

  function logout() {
    signout(() => {
      navigate('/');
    });
  }

  useEffect(() => {
    getUsers();
  }, []);

  function getUsers() {
    setUsers(null);
    setAllUsers(null);
    const qs = queryString.stringify({ 'company': user.company, 'role': 'operator' });
    try {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_PREFIX}/users/getUsers?${qs}`,
        headers: { Authorization: `Bearer ${user.token}` }
      })
        .then(
          response => {
            if (response && response.data && response.data.result) {
              setUsers(response.data.result);
              setAllUsers(response.data.result);
            } else {
              setAllUsers([]);
            }
          },
          error => {
            if (error.response.status === 401) {
              // alert('Token Expired.Please login again.');
              logout();
            }
          }
        )
        .catch(error => {
          console.log('error:', error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function handleUserSearch() {
    if (!allUsers) return true;
    const data = allUsers.filter((user, index) => user.value.username.indexOf(searchInput) >= 0);
    setUsers(data);
  }

  return (
    <main className="create_user_page">
      <section className="banner-display-1 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-9">
              <div className="entry-heading">
                <h2 className="  text-white fw-bold d-flex align-items-center">
                  Web&nbsp;<span className="img-l"> Cloud </span>&nbsp;Server
                </h2>
                <p className="fs-14 fw-bold text-white">
                  Scegli la procedura per entrare nel web Cloud Server per poter generare le missioni
                  <br /> inerenti alla giornata!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mission-details  position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-9">
              <div className="mission_success bottom_shadow round  position-relative">
                <div
                  className="cp-40 cpy-30 bg-white rounded-6 text-center w-100 p-3 userManagementPanel"
                  style={{
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                  }}
                >
                  <div className="h-100 w-100 position-relative">
                    <h2 className="fs-34 fw-semibold text-content cmb-0 text-blue3 pt-5">Gestione Utente</h2>
                    <h3 className="fs-28 fw-semibold text-black">Crea gli utenti per i tuoi device</h3>
                    <div className="addUser">
                      <Link
                        className="btn btn-blue2 fw-bold  rounded-pill border-2  position-relative"
                        style={{ marginLeft: '1rem', marginRight: '1rem', paddingRight: '1rem !important' }}
                        to="/create-user"
                        state={{ selection: location.state && location.state.selection ? location.state.selection : '' }}
                      >
                        <img className="position-absolute start-0 ml-1" src="/images/add.svg" alt="" style={{ marginLeft: '1rem' }} />
                        <span>AGGIUNGI UTENTE </span>
                      </Link>
                    </div>

                    <div className="row align-items-center justify-content-center pb-3">
                      <div className="col-7">
                        <input
                          type="text"
                          className="form-control rounded-pill border-2 p-2 d-inline border border-blue5 text-purple"
                          aria-describedby="emailHelp"
                          placeholder="Username"
                          onChange={e => {
                            setSearchInput(e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setSearchInput(e.target.value);
                              handleUserSearch();
                            }
                          }}
                        />
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-blue rounded-pill d-inline"
                          onClick={() => {
                            handleUserSearch();
                          }}
                        >
                          CERCA
                        </button>
                      </div>
                    </div>
                  </div>


                  <div className="userContainer">
                    {users === null ? (
                      <h6 className="mt-4 text-center text-gray8">Caricamento...</h6>
                    ) : users.length === 0 ? (
                      <h6 className="mt-4 text-center text-gray8">Nessun dato trovato.</h6>
                    ) : (
                      users.map((user, index) => {
                        return (
                          <div
                            key={`user-${index}`}
                            className={`card border border-2 border-gray7 rounded ${activeUsername === user.value.name ? ' border border-red ' : ''}`}
                            onClick={() => {
                              setActiveUsername(user.value.name);
                            }}
                          >
                            {/* <div className={`w-100 d-flex `} 
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setActiveUsername(user.value.name);
                                }}> */}
                            <img src="/images/user.svg" alt="..." />
                            <div className="titles">
                              <h5 className="text-gray8" style={{ fontSize: '1.1rem' }}>
                                Username
                              </h5>
                              <h5 className="text-gray8 text-capitalize" style={{ fontSize: '1.1rem' }}>
                                {user.value.name}
                              </h5>
                            </div>
                            <Link to={`/change-user-password/${user.value.name}`}> Modifica </Link>
                          </div>
                        );
                      })
                    )}
                  </div>

                  <div className='footerButtons pb-3'>
                        <Link
                          to={location.state && location.state.selection ? `/upload/${location.state.selection}` : '/selection'}
                          className="btn btn-blue2 fw-bold py-3  rounded-pill border-2 position-relative"
                         
                        >
                          <img className="" src="/images/back.svg" alt="" />
                          INDIETRO
                        </Link>
                        <button
                          type="button"
                          className="btn  bg-gradient1 text-white fw-bold  py-3 rounded-pill"
                        
                          onClick={() => {
                            setShowDeleteUserModal(true);
                            // handleDeleteUser();
                          }}
                        >
                          ELIMINA
                        </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 pb-5"></div>
        </div>
      </section>
      {
        showDeleteUserModal && activeUsername && (
          <DeleteUserModal
            username={activeUsername}
            _onSuccess={() => {
              setShowDeleteUserModal(false);
              getUsers();
            }}
            _onCancel={() => {
              setShowDeleteUserModal(false);
            }}
          />
        )
      }
    </main >
  );
}

export default Users;
