import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Styles from './Styles';
import Login from './Login';
import Selection from './Selection';
import Upload from './Upload';
import Waves from './Waves';
import Header from './Header';
import CreateUser from './CreateUser';
import Users from './Users';
import ChangePassword from './ChangePassword';
import useAuth from '../hooks/useAuth';
import AuthProvider from '../providers/AuthProvider';
import ScrollToTop from '../ScrollToTop';
import UserChangePassword from './UserChangePassword';

function Layout(props) {
  const { hasBlueHeader, noHeader } = props;
  if (noHeader) {
    return <Outlet />;
  } else {
    return (
      <>
        <Header hasBlueHeader={hasBlueHeader} />
        <div id="scroll" className="" style={{ paddingTop: '6rem' }}>
          <Outlet />
        </div>
      </>
    );
  }
}

function PublicRoute({ children }) {
  let auth = useAuth();

  if (auth.user) {
    return <Navigate to="/selection" replace />;
  }

  return children;
}
function PrivateRoute({ children }) {
  let auth = useAuth();

  if (!auth.user) {
    return <Navigate to="/" replace />;
  }
  return children;
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route element={<Layout hasBlueHeader={true} />}>
            <Route
              path="/waves/:waveType"
              element={
                <PrivateRoute>
                  <Waves />
                </PrivateRoute>
              }
            />
            <Route path="/app" element={<Styles />} />
          </Route>
          <Route element={<Layout />}>
            <Route
              path="/upload/:waveType"
              element={
                <PrivateRoute>
                  <Upload />
                </PrivateRoute>
              }
            />
            <Route
              path="/selection"
              element={
                <PrivateRoute>
                  <Selection />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-user"
              element={
                <PrivateRoute>
                  <CreateUser />
                </PrivateRoute>
              }
            />
            <Route
              path="/change-password"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/change-user-password/:username"
              element={
                <PrivateRoute>
                  <UserChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
