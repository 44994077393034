import axios from 'axios';
import { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';

function DeleteWaveModal(props) {
  const { aWaveDeleteIds, _onSuccess, _onCancel } = props;
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    setShowModal(true);
  }, []);

  function handleDeleteWaves() {
    if(deleting) return;
    
    setDeleting(true);
    try {
      axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_PREFIX}/bonzai/waves/deleteWaves`,
        data: JSON.stringify({ 'company': user.company, 'wavetype': props.waveType, 'productID': user.company, 'waveids': aWaveDeleteIds }),
        headers: { Authorization: `Bearer ${user.token}`, 'Content-Type': 'application/json' }
      }).then(
        response => {
          _onSuccess();
          setShowModal(false);
          setDeleting(false);
        },
        error => {
          _onCancel();
          alert("something went wrong. Please try again.")
          setDeleting(false);
        }
      );
    } catch (error) {
      _onCancel();
      alert("something went wrong. Please try again.")
      setDeleting(false);
      console.log(error);
    }
  }

  return (
    <div
      className={`modal fade ${showModal ? ' show d-block ' : ''}`}
      id="fileViewModal1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content bg-white-transparent">
          <div className="modal-body text-center text-purple text-bold m-5">
            <h2 className="text-purple2 mb-3 fw-bold">Sei sicuro di voler cancellare i file? </h2>
            <p className="mb-4">Perderai anche i Record legati a quei file</p>
            <div className="align-items-center w-100">
              <div className="d-flex" style={{ justifyContent: 'space-around', display: 'flex' }}>
                <button
                  className={`btn btn-blue border-0 bg-gradient1 text-gray6 fw-bold px-3 rounded-4 w-100 mx-3 ${deleting && "disabled"}`}
                  onClick={() => {
                    !deleting && handleDeleteWaves();
                  }}
                >
                  CANCELLA
                </button>
                <button
                  className="btn btn-blue border-0 bg-gradient2 text-gray6 fw-bold px-3 rounded-4 w-100 mx-3"
                  onClick={() => {
                    _onCancel();
                  }}
                >
                  ANNULLA
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteWaveModal;
