import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function ChangePassword() {
  /* const [inputs, setInputs] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  }); */

  const [oldPassword, setOldPassword] = useState( "");
  const [newPassword, setNewPassword] = useState( "");
  const [confirmPassword, setConfirmPassword] = useState( "");

  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  const clearForm = () => {
    // Implement this function
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };


  function handleSubmit(e) {
    e.preventDefault();
    if (submitted) return;

    setSubmitted(true);
    if (newPassword !== confirmPassword) {
      setErrorMessage('La nuova password inserita non coincide con la conferma.');
      setSubmitted(false);
      return;
    }
    let formData = {
      company: user.company,
      user: user.company,
      oldpassword: oldPassword,
      password: newPassword
    };
    axios
      .post(
        process.env.REACT_APP_API_PREFIX + '/users/postUserChangePwd',
        { ...formData },
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }
        }
      )
      .then(response => {
        if (response && response.data && response.data.status === 'ok') {
          setErrorMessage(null); // Clear error message on success
          clearForm();
          navigate('/users');
        } else {
          setErrorMessage(response.data.message || 'something went wrong!');
        }
      })
      .catch(error => {
        setErrorMessage('Network Error. Please try again later.');
      })
      .finally(() => {
        setSubmitted(false); // Enable submit button after request completion
      });
  }

  /* function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
 */
  return (
    <main className="create_user_page">
      <section className="banner-display-1 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-9">
              <div className="entry-heading">
                <h2 className="  text-white fw-bold d-flex align-items-center">
                  Web&nbsp;<span className="img-l"> Cloud </span>&nbsp;Server
                </h2>
                <p className="fs-14 fw-bold text-white">
                  Scegli la procedura per entrare nel web Cloud Server per poter generare le missioni
                  <br /> inerenti alla giornata!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mission-details  position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-9">
              {/* <!-- mission generate successfully--> */}
              <div className="mission_success bottom_shadow round  position-relative">
                <div
                  className="cp-40 cpy-30 bg-white rounded-6 text-center w-100"
                  style={{
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                  }}
                >
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}>
                    <div>
                      <h2 className="fs-34 fw-semibold text-content cmb-0 text-blue3 py-5">Modifica Password</h2>
                    </div>
                    <div className="container bg-gray3 d-flex justify-content-center align-items-center" style={{
                      background: "linear-gradient(to right, #4166b0, #9edcea)"
                    }}>
                      <div className="col-8">
                        <div className="py-5">
                          {errorMessage && (
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                              <strong>Errore!</strong> {errorMessage}
                              <button onClick={()=> {setErrorMessage(null); clearForm()}} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                          )}
                          <div className="my-5">
                            <input value={oldPassword} type="password" name="oldPassword" className="form-control form-control-lg text-gray8 p-2 px-4 rounded-5" id="exampleInputPassword1" placeholder="Vecchia Password" style={{ fontSize: '1rem' }} onChange={(e)=> setOldPassword(e.target.value)} required />
                          </div>
                          <div className="my-5">
                            <input value={newPassword} type="password" name="newPassword" className="form-control form-control-lg text-gray8 p-2 px-4 rounded-5" id="exampleInputPassword2" placeholder="Nuova Password" style={{ fontSize: '1rem' }} onChange={(e)=> setNewPassword(e.target.value)} required />
                          </div>
                          <div className="my-5">
                            <input value={confirmPassword} type="password" name="confirmPassword" className="form-control form-control-lg text-gray8 p-2 px-4 rounded-5" id="exampleInputPassword2" placeholder="Conferma Password" style={{ fontSize: '1rem' }} onChange={(e)=> setConfirmPassword(e.target.value)}  required />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='footerButtons py-5'>
                        <button
                         onClick={() => {
                          navigate(-1);
                        }}
                          className="btn btn-blue2 fw-bold py-3 px-4 rounded-pill border-2 position-relative"
                        >
                          <img className="" src="/images/back.svg" alt="" />
                          INDIETRO
                        </button>
                        <button type="submit" className="btn btn-green2  text-white fw-bold py-3 rounded-pill">
                          CONFERMA
                        </button>
                      </div>


                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 pb-5"></div>
        </div>
      </section>
    </main>
  );
}

export default ChangePassword;
