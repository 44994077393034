import { useEffect, useState } from 'react';
import{ useNavigate} from "react-router-dom";
import useAuth from '../hooks/useAuth';

function LogoutModal(props) {
  const { _onCancel } = props;
  const [showModal, setShowModal] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setShowModal(true);
  }, []);

  
  function handleLogout() {
    setShowModal(false);
    auth.signout(() => {
      navigate('/');
    });
  }

  return (
    <div
      className={`modal fade ${showModal ? ' show d-block ' : ''}`}
      id="fileViewModal1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content bg-white-transparent">
          <div className="modal-body text-center text-purple text-bold  m-5">
            <h2 className='text-purple2 mb-4 fw-bold'>Sei sicuro di voler uscire dal tuo account </h2>
            <div className="align-items-center w-100">
              <div className="d-flex" style={{ justifyContent: 'space-around', display: 'flex' }}>
                <button
                  className="btn btn-blue border-0 bg-gradient1 text-gray6 fw-bold px-3 rounded-4 w-100 mx-3"
                 
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  ESCI
                </button>
                <button
                  className="btn btn-blue border-0 bg-gradient2 text-gray6 fw-bold px-3 rounded-4 w-100 mx-3"
                  onClick={() => {
                    _onCancel();
                  }}
                >
                  ANNULLA
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoutModal;
