import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const cards = [
  {
    img: "/images/ar-picking.svg",
    title: "AR Picking",
    description: "Gestisci tutte le missioni di “Picking” per affidare ai tuoi user le missioni della giornata",
    buttonName: "INIZIA",
    link: "/upload/arpicking"
  },
  {
    img: "/images/ar-put-light.svg",
    title: "AR Put To Light",
    description: `Gestisci tutte le missioni di “Put To Light” per affidare ai tuoi user le missioni della giornata`,
    buttonName: "INIZIA",
    link: "/upload/arputtolight"
  },
]

function Selection() {
  return (
    <Fragment>
      <div className="selection_page position-absolute top-0 w-100" style={{ height: "35rem", zIndex: -1 }}></div>

      <div className="container-selection">
        <div className="content-selection">
          <div className="content1">
            <div className='entry-heading'>
              <h2>
                Web&nbsp;<span className="img-l"> Cloud </span>&nbsp;Server
              </h2>
              <p>
                Scegli la procedura per entrare nel web Cloud Server per poter generare le missioni
                <br /> inerenti alla giornata!
              </p>
            </div>

            <div className="selectionContainer" >
              {cards.map((card, index) => {
                return (
                    <div className="card "  key={index}>
                      <div className='border-0 text-center rounded-38' style={{
                      backgroundImage: 'linear-gradient(90deg, #4465af 0%, #8dd5e7 100%)'
                    }}>
                      <div className="card-thumb" style={{ marginTop: '-38px' }}>
                        <img src={card.img} className="img-fluid" alt="..." />
                      </div>
                      <div className='card-body card-body-selection'>
                        <h5 className="">{card.title}</h5>
                        <p className="card-text text-white fw-light fs-19 lh-21">{card.description}</p>
                      </div>
                    
                    </div>
                    <Link className="btn btn-blue bg-blue fw-bold px-5 py-3 rounded-4 border-2 mt-4 mb-5" to={card.link}>{card.buttonName}
                    </Link>
                  </div>
                )
              })}
            </div>
            {/*  <div className="row w-100 justify-content-center" style={{ marginTop: '5rem' }}>
              <div className="col-sm-11">
                <div className="row justify-content-center">
                  {cards.map((card, index) => {
                    return (
                      <div className="card-selection" key={index}>
                        <div className="card border-0 text-center rounded-38" style={{
                          backgroundImage: 'linear-gradient(90deg, #4465af 0%, #8dd5e7 100%)'
                        }}>
                          <div className="card-thumb" style={{ marginTop: '-38px' }}>
                            <img src={card.img} className="img-fluid" alt="..." />
                          </div>
                          <div className='card-body card-body-selection'>
                            <h5 className="">{card.title}</h5>
                            <p className="card-text text-white fw-light fs-19 lh-21">{card.description}</p>
                          </div>
                        </div>
                        <Link className="btn btn-blue bg-blue fw-bold px-5 py-3 rounded-4 border-2 mt-4 mb-5" to={card.link}>{card.buttonName}
                        </Link>
                      </div>
                    )
                  })}

                </div>
              </div>
            </div> */}

          </div>
        </div></div>
      <div className="top_left_circle"></div>
      <div className="right_center_circle"></div>
    </Fragment>
  );
}

export default Selection;
