import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import LogoutModal from "./LogoutModal";
import { useState } from "react";

function Header(props) {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { hasBlueHeader } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { signout, user } = useAuth();

  return (
    <header className="w-100">
      <nav
        className={`navbar navbar-expand-lg  position-fixed w-100 ${
          hasBlueHeader ? "bg-gradient2" : "bg-white"
        }`}
        style={{ height: "6rem", zIndex: 1000 }}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            {hasBlueHeader ? (
              <img src="/images/logo-white.png" className="ms-5" />
            ) : (
              <img src="/images/logo.png" className="ms-5" />
            )}
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul
              className="navbar-nav align-items-center "
              style={{ marginLeft: "auto" }}
            >
              {(location.pathname === "/waves/arputtolight" ||
                location.pathname === "/waves/arpicking") && (
                <li className="nav-item">
                  <a
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="btn btn-white fw-bold  py-2 rounded-pill border-2  p-3 mx-3"
                  >
                    <img
                      src="/images/back-blue.svg"
                      className="img-fluid p-1 px-2"
                    />
                    INDIETRO
                  </a>
                </li>
              )}

              <li className="nav-item cms-20 bg-white rounded-pill p-2">
                <div className="user-profile d-flex align-items-center px-3">
                  <div className="user-details" >
                    <h5
                      className="name dropdown-toggle fs-16 m-auto"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                      src="/images/avatar.png"
                      alt="User Avatar"
                      className="avatar"
                      />
                      Ciao {user && user.username ? user.username : ""} !
                    </h5>
                    <div className="dropdown">
                      <ul
                        className="dropdown-menu dropdown-menu-end border-0 shadow-lg"
                        aria-labelledby="dropdownMenuButton"
                        style={{minWidth: "100%",top: "1rem"}}
                      >
                        <li>
                          <Link className="dropdown-item" to="/change-password">
                            Modifica Password
                          </Link>
                        </li>
                        <li>
                          <span
                            className="dropdown-item text-red link"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowLogoutModal(true);
                            }}
                          >
                            Esci
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {showLogoutModal && (
        <LogoutModal
          _onCancel={() => {
            setShowLogoutModal(false);
          }}
        />
      )}
    </header>
  );
}
export default Header;
