import axios from 'axios';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import UserQRCode from './UserQRCode';

function UserChangePassword() {
  const [activeStep, setActiveStep] = useState(1);
  const [inputs, setInputs] = useState({
    'newPassword': '',
    'confirmPassword': ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [downloadPDF, setDownloadPDF] = useState(null);
  const navigate = useNavigate();
  const { username } = useParams();
  const { user } = useAuth();

  function handleStep1Submit(e) {
    e.preventDefault();
    setErrorMessage(null);
    if (username === null || inputs.newPassword === null || inputs.confirmPassword === null) {
      setErrorMessage('Please enter details');
      return true;
    }
    if (inputs.newPassword !== inputs.confirmPassword) {
      setErrorMessage('Password and Confirm password does not match.');
      return true;
    }
    setActiveStep(2);
  }

  function handleStep2Submit(e) {
    e.preventDefault();
    if (submitted) return true;
    setSubmitted(true);
    setErrorMessage(null);
    let formData = {
      'company': user.company,
      'user': username,
      'password': inputs.newPassword
    };
    axios
      .post(
        process.env.REACT_APP_API_PREFIX + '/users/postUserChangePwd',
        { ...formData },
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }
        }
      )
      .then(response => {
        if (response && response.data && response.data.status === 'ok') {
          navigate('/users');
        } else {
          setErrorMessage(response.data.message || 'something went wrong!');
        }
      });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  return (
    <main className="create_user_page">
      <section className="banner-display-1 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-9">
              <div className="entry-heading">
                <h2 className="  text-white fw-bold d-flex align-items-center" >
                  Web&nbsp;<span className="img-l"> Cloud </span>&nbsp;Server
                </h2>
                <p className="fs-14 fw-bold text-white">
                  Scegli la procedura per entrare nel web Cloud Server per poter generare le missioni
                  <br /> inerenti alla giornata!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mission-details  position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-9">
              {/* <!-- mission generate successfully--> */}
              <div className="mission_success bottom_shadow round  position-relative">
                <div
                  className="cp-40 cpy-30 bg-white rounded-6 text-center w-100"
                  style={{
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                  }}
                >
                  <div>
                    <h2 className="fs-34 fw-semibold text-content cmb-0 text-blue3 py-5">Modifica Password</h2>
                  </div>
                  {activeStep === 1 && (
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        handleStep1Submit(e);
                      }}
                    >
                      <div
                        className="container bg-gray3 d-flex justify-content-center align-items-center"
                        style={{
                          background: 'linear-gradient(to right, #4166b0, #9edcea)'
                        }}
                      >
                        <div className="col-8">
                          <div className="py-5">
                            {errorMessage && (
                              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                <strong>Error!</strong> {errorMessage}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                              </div>
                            )}
                            <div className="my-5">
                              <input
                                type="password"
                                name="newPassword"
                                className="form-control form-control-lg text-gray8 p-2 px-4 rounded-5"
                                id="exampleInputPassword2"
                                placeholder="Nuova Password"
                                style={{ fontSize: '1rem' }}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="my-5">
                              <input
                                type="password"
                                name="confirmPassword"
                                className="form-control form-control-lg text-gray8 p-2 px-4 rounded-5"
                                id="exampleInputPassword2"
                                placeholder="Conferma Password"
                                style={{ fontSize: '1rem' }}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='footerButtons py-5'>
                        <button
                         onClick={() => {
                          navigate(-1);
                        }}
                          className="btn btn-blue2 fw-bold py-3 px-4 rounded-pill border-2 position-relative"

                        >
                          <img className="" src="/images/back.svg" alt="" />
                          INDIETRO
                        </button>
                        <button type="submit" className="btn btn-green2  text-white fw-bold py-3 rounded-pill">
                              CONTINUA
                        </button>
                      </div>


                    </form>
                  )}

                  {activeStep === 2 && (
                    <form
                      method="post"
                      onSubmit={e => {
                        handleStep2Submit(e);
                      }}
                    >
                      <div className="container bg-gray3 d-flex justify-content-center align-items-center add-user-wrapper">
                        <div className="col-8 py-5">
                          {errorMessage && (
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                              <strong>Errore!</strong> {errorMessage}
                              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                          )}
                          {downloadPDF ? (
                            <>
                              <UserQRCode
                                key={Math.random()}
                                username={username}
                                password={inputs.newPassword}
                                qrCodeText={downloadPDF}
                                successCallback={() => {
                                  setDownloadPDF(null);
                                }}
                              />
                            </>
                          ) : null}

                          <h4>
                            <b>Scarica QRCode</b>
                          </h4>
                          <img
                            className="border-0 m-2"
                            src="/images/qrcode-black.svg"
                            alt=""
                            style={{ width: '4.5rem', cursor: 'pointer' }}
                            onClick={() => {
                              setDownloadPDF(true);
                            }}
                          />
                        </div>
                      </div>


                      <div className='footerButtons py-5'>
                        <button
                         onClick={() => {
                          setActiveStep(1);
                        }}
                          to="/users"
                          className="btn btn-blue2 fw-bold py-3 px-4 rounded-pill border-2 position-relative"

                        >
                          <img className="" src="/images/back.svg" alt="" />
                          INDIETRO
                        </button>
                        <button type="submit" className="btn btn-green2  text-white fw-bold py-3 rounded-pill">
                          CONFERMA
                        </button>
                      </div>


                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 pb-5"></div>
        </div>
      </section>
    </main>
  );
}

export default UserChangePassword;
