import axios from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';

function DeleteUserModal(props) {
  const { username, _onSuccess, _onCancel } = props;
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    setShowModal(true);
  }, []);

  function handleDeleteUser() {
    if (!username) return true;
    const qs = queryString.stringify({ company: user.company, user: username  });
    try {
      axios
        .delete(`${process.env.REACT_APP_API_PREFIX}/users/deleteUser?${qs}`, {
          headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${user.token}` }
        })
        .then(
          success => {
            setShowModal(false);
            _onSuccess();
          },
          error => {}
        );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div
      className={`modal fade ${showModal ? ' show d-block ' : ''}`}
      id="fileViewModal1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content bg-white-transparent">
          <div className="modal-body text-center text-purple text-bold  m-5">
            <h2>Sei sicuro di voler cancellare l'utente selezionato? </h2>
            <p>Perderai anche tutti i record ad esso collegati</p>
            <div className="align-items-center w-100">
              <div className="d-flex" style={{ justifyContent: 'space-around', display: 'flex' }}>
                <button className="btn btn-blue border-0 bg-gradient1 text-gray6 fw-bold px-3 rounded-4 w-100 mx-3" onClick={() => {
                  handleDeleteUser();
                  }}>CANCELLA</button>
                <button
                  className="btn btn-blue border-0 bg-gradient2 text-gray6 fw-bold px-3 rounded-4 w-100 mx-3"
                  onClick={() => {
                    _onCancel();
                    setShowModal(false);
                  }}
                >
                  ANNULLA
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteUserModal;
