function Styles() {
    const colors = [
        "blue", "blue2", "lblue3", "blue3", "blue4", "blue5", "blue6", "green", "green2", "lgreen", "green3", "lgreen3", "green4", "purple", "lpurple", "purple2", "pink", "lpink", "white", "gray", "gray2", "gray3", "gray4", "gray5", "gray6", "gray7", "gray8","navy", "orange", "red"]
    return (
        <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="buttons-tab" data-bs-toggle="tab" data-bs-target="#buttons-tab-pane" type="button" role="tab" aria-controls="buttons-tab-pane" aria-selected="true">Buttons</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="backgrounds-tab" data-bs-toggle="tab" data-bs-target="#backgrounds-tab-pane" type="button" role="tab" aria-controls="backgrounds-tab-pane" aria-selected="false">Backgrounds</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="borders-tab" data-bs-toggle="tab" data-bs-target="#borders-tab-pane" type="button" role="tab" aria-controls="borders-tab-pane" aria-selected="false">Borders</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="texts-tab" data-bs-toggle="tab" data-bs-target="#texts-tab-pane" type="button" role="tab" aria-controls="texts-tab-pane" aria-selected="false">Texts</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="buttons-tab-pane" role="tabpanel" aria-labelledby="buttons-tab" tabindex="0">
                    <div className="row">
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <div><button type="button" className="btn btn-blue fw-bold px-5 py-3 rounded-4">SCOPRI</button></div>
                                <span>btn btn-blue fw-bold px-5 py-3 rounded-4</span>
                            </div>
                        </div>
                        <div className="col-6 my-3 p-2 bg-blue2">
                            <div className="d-flex flex-column align-items-center">
                                <button type="button" className="btn btn-blue bg-transparent fw-bold px-5 py-3 rounded-4 border-2">INIZIA</button>
                                <span className="text-white">btn btn-blue bg-transparent fw-bold px-5 py-3 rounded-4 border-2</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <button type="button" className="btn btn-blue fw-bold px-5 py-3 rounded-pill">CERCA</button>
                                <span>btn btn-blue fw-bold px-5 py-3 rounded-pill</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <button type="button" className="btn btn-blue fw-bold px-5 py-2 rounded-4 fs-4">GENERA</button>
                                <span>btn btn-blue fw-bold px-5 py-2 rounded-4 fs-4</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <button type="button" disabled className="btn btn-blue fw-bold px-5 py-2 rounded-4 fs-4">GENERA</button>
                                <span>btn btn-blue fw-bold px-5 py-2 rounded-4 fs-4</span>
                            </div>
                        </div>
                        <div className="col-6 my-3 p-2 bg-blue">
                            <div className="d-flex flex-column align-items-center">
                                <button type="button" className="btn btn-white fw-bold px-5 py-3 rounded-pill border-2">INDIETRO</button>
                                <span>btn btn-white fw-bold px-5 py-3 rounded-pill border-2</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <button type="button" className="btn btn-blue2 fw-bold px-5 py-3 rounded-pill border-2">INDIETRO</button>
                                <span>btn btn-blue2 fw-bold px-5 py-3 rounded-pill border-2</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <button type="button" className="btn btn-green2 fw-bold px-5 py-3 rounded-pill">CONFERMA</button>
                                <span>btn btn-green2 fw-bold px-5 py-3 rounded-pill</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <button type="button" className="btn btn-green fw-bold px-4 py-3 rounded-pill">VISUALIZZA</button>
                                <span>btn btn-green fw-bold px-4 py-3 rounded-pill</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <button type="button" className="btn btn-purple fw-bold px-4 py-3 rounded-pill">SCARICA</button>
                                <span>btn btn-purple fw-bold px-4 py-3 rounded-pill</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <button type="button" className="btn btn-pink fw-bold px-4 py-3 rounded-pill">ELIMINA</button>
                                <span>btn btn-pink fw-bold px-4 py-3 rounded-pill</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <div><button type="button" className="btn btn-blue border-0 bg-gradient1 text-gray6 fw-bold px-5 py-3 rounded-4">ENTRA</button></div>
                                <span>btn btn-blue border-0 bg-gradient1 text-gray6 fw-bold px-5 py-3 rounded-4</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <div><button type="button" className="btn btn-blue border-0 bg-gradient1 text-gray6 fw-bold px-5 py-3 rounded-4">ESCI</button></div>
                                <span>btn btn-blue border-0 bg-gradient1 text-gray6 fw-bold px-5 py-3 rounded-4</span>
                            </div>
                        </div>
                        <div className="col-6 my-3">
                            <div className="d-flex flex-column align-items-center">
                                <div><button type="button" className="btn btn-blue border-0 bg-gradient2 text-gray6 fw-bold px-5 py-3 rounded-4">INDIETRO</button></div>
                                <span>btn btn-blue border-0 bg-gradient2 text-gray6 fw-bold px-5 py-3 rounded-4</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="backgrounds-tab-pane" role="tabpanel" aria-labelledby="backgrounds-tab" tabindex="0">
                    <div className="row">
                        {colors.map((color, colorIndex) => {
                            return (
                                <div className="col-3" key={colorIndex}>
                                    <div className={`my-3 p-4 fw-bold fs-4 bg-${color}`}>
                                        bg-{color}
                                    </div>
                                </div>
                            )
                        })}
                        <div className="col-3">
                            <div className={`my-3 p-4 fw-bold fs-4 bg-gradient2`}>
                                bg-gradient2
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="borders-tab-pane" role="tabpanel" aria-labelledby="borders-tab" tabindex="0">
                    <div className="row">
                        {colors.map((color, colorIndex) => {
                            return (
                                <div className="col-3" key={colorIndex}>
                                    <div className={`my-3 p-4 fw-bold fs-4 border border-2 border-${color}`}>
                                        border border-{color}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="tab-pane fade" id="texts-tab-pane" role="tabpanel" aria-labelledby="texts-tab" tabindex="0">
                    <div className="row">
                        {colors.map((color, colorIndex) => {
                            return (
                                <div className="col-3" key={colorIndex}>
                                    <div className={`my-3 p-4 fw-bold fs-4 text-${color}`}>
                                        text-{color}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Styles;
