import { useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

function AuthProvider({ children }) {
    const localStorageUser = localStorage.getItem("user");
    const initalUser = localStorageUser ? JSON.parse(localStorageUser) : null;
    let [user, setUser] = useState(initalUser);
    let [username, setUsername] = useState(initalUser && initalUser.username ? initalUser.username : "");

    let signin = (newUser, callback) => {
        localStorage.setItem('user', JSON.stringify(newUser));
        setUser(newUser);
        setUsername(newUser.username ? newUser.username : "")
        callback();

    };

    let updateUser = (userObj, callback) => {
        const newUser = { ...user, ...userObj };
        localStorage.setItem('user', JSON.stringify(newUser));
        setUser(newUser);
        setUsername(newUser && newUser.username ? newUser.username : username)
        callback();
    }

    let signout = (callback) => {
        localStorage.removeItem('user');
        setUser(null);
        callback();

    };

    let value = { user, username, signin, signout, updateUser };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;