import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import QRCode from 'qrcode.react';
import React, { useEffect } from 'react';

function UserQRCode(props) {
  const {  username, password } = props;
  const qrCodeText= `${username}-${password}`;

  useEffect(() => {
    downloadPDF();
  }, []);

  const downloadPDF = () => {
    const pdfContent = document.getElementById('pdfContent');
    html2canvas(pdfContent).then(canvas => {
      const imgData = canvas.toDataURL('image/svg');
      var pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4'
      });

      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 50, width, height); // 56, ( 154, 242 )
      pdf.save(username + '.pdf');
    });
    props.successCallback();
  };

  return (
    <div id="pdfContent" style={{ textAlign: 'center', height: '1403px', width: '992px', top: '-100rem', position: 'absolute' }}>
      <h1 style={{ padding: '3rem 0rem' }}> </h1>
      <div >
        <QRCode size={730} id={`${username}`} value={'' + qrCodeText} style={{ padding: '6rem', border: 'solid 0.5rem #000', borderRadius: '2rem' }} />
      </div>
      <h2 style={{ margin: '6rem 0rem' }}>
        <b>Username: {username}  </b>
      </h2>
      <img src="/images/logo.png" width="160" alt="" style={{ float: 'right', bottom: 0, margin: '0rem 2.8rem' }} />
    </div>
  );
}
export default UserQRCode;
