import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';

function Login() {
  let auth = useAuth();
  const [inputs, setInputs] = useState({
    user: '',
    password: '',
    company: 'bonzai',
    productID: 'bonzai'
  });
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  function handleOnLoginReq(e) {
    e.preventDefault();
    if (submitted) return true;

    setSubmitted(true);
    axios.post(process.env.REACT_APP_API_PREFIX + '/login/login', { ...inputs }).then(response => {
      if (response && response.data && response.data.status === 'ok') {
        const result = response.data.result;
        if(result.role.role === "administrator"){
          auth.signin({ token: result.token, ...result.user }, () => {
            navigate('/selection')
          })
        }else{
          setErrorMessage('Invalid admin login details. Please try again.');
        }
        setSubmitted(false);
      } else {
        setErrorMessage(response.data.message || 'something went wrong!');
        setSubmitted(false);
      }
    });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  return (
    <div className="login_page">
      <form onSubmit={handleOnLoginReq} className="row h-100 m-0 p-0">
        <div className="login_page_left">
          <img src="/images/logo-s.png" alt=""/>

          <div className="first_text">
           Benvenuto in
          </div>
          <img src="/images/bonzai-text.svg" alt=""/>
          <div className="second_text">
            Web Cloud Server
          </div>
          <button type="submit" className="btn btn-blue bg-gradient1 text-gray6 fs-1 fw-bold " to="/selection">
            ENTRA
          </button>
        </div>
        <div className="login_page_right">
          <div className="auth">
            <div className="d-flex justify-content-center align-items-center">
              <img src="/images/avatar.svg" className="avatar" alt='' />
              <div>
                <h1>Entra</h1>
                <div>
                  <span>Usa le tue credenziali per</span>
                  <br />
                  <span>accedere al tuo account !</span>
                </div>
              </div>
            </div>
            <div>
              {errorMessage && (
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                  <strong>Errore!</strong> {errorMessage}
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
              )}
              <input
                name="user"
                type="text"
                className="form-control form-control-lg bg-gradient3 text-gray8 fs-3 rounded-4"
                placeholder="Username"
                required
                onChange={handleChange}
              />
              <input
                name="password"
                type="password"
                className="form-control form-control-lg bg-gradient3 text-gray8 fs-3 rounded-4"
                placeholder="Password"
                required
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
