import axios from 'axios';
import queryString from 'query-string';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteWaveModal from './DeleteWaveModal';
import useAuth from '../hooks/useAuth';
import WaveQRCode from './WaveQRCode';
import * as XLSX from 'xlsx';

function Waves() {
  const [waves, setWaves] = useState([]);
  const [allWaves, setAllWaves] = useState(null);
  const [waveData, setWaveData] = useState();
  const [searchByStatus, setSearchByStatus] = useState("");
  const [searchByWaveId, setSearchByWaveId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [visibleWaveId, setVisibleWaveId] = useState(null);
  const [downloadPDF, setDownloadPDF] = useState(null);
  const [aWaveDeleteIds, setAWaveDeleteIds] = useState([]);
  const [loading, setLoading] = useState(true);

  const { waveType } = useParams();
  const navigate = useNavigate();
  const { user, signout } = useAuth();

  function logout() {
    signout(() => {
      navigate('/');
    });
  }

  function getOrderStatus(pickedQty, sourceTargetQty, user) {
    if (user) {
      return ((sourceTargetQty - pickedQty) === 0) ? "completed" : "uncompleted";
    }
    else
      return "untaken"

  }

  function getOrderColor(pickedQty, sourceTargetQty, user) {
    const status = getOrderStatus(pickedQty, sourceTargetQty, user)
    switch (status) {
      case "completed":
        return "lightgray";
      case "uncompleted":
        return "#FAA99B";
      default:
        return "none"
    }
  }

  function getWaves() {
    setWaves([]);
    setAllWaves([]);
    setLoading(true);
    const qs = queryString.stringify({ 'company': user.company, 'wavetype': waveType });
    try {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_PREFIX}/bonzai/waves/getWaves?${qs}`,
        headers: { Authorization: `Bearer ${user.token}` }
      })
        .then(
          response => {

            if (response && response.data && response.data.result) {

              const temp = response.data.result.map(item => {
                const { ordersCount, ordersTaken, percentage } = item.value;

                /* Stato Anomalia: quando tutti gli ordini della wave son stati presi in carico (tutti con user != "") ma la percentuale di completamento non e' il 100% (ovvero qualche riga rossa, quantita' presa e quantita' richiesta differiscono) */
                //ordersCount = somma di tutti gli ordini
                //ordersTaken= somma di tutti gli ordini presi in carico (cioe' con user!= '')
                let status = (item.value.ordersTaken === 0) ? 'default' : item.value.percentage === 100 ? 'completed' : (item.value.ordersTaken < item.value.ordersCount ? 'progress' : 'anomaly');
                item = { ...item.value, 'status': status };
                return item;
              });

              setWaves(temp);
              setAllWaves(temp);
              setLoading(false);
            } else {
              setLoading(false);
              setWaves([]);
              setAllWaves([]);
            }
          },
          error => {
            if (error.response.status === 401) {
              // alert('Token Expired.Please login again.');
              logout();
            }
          }
        )
        .catch(error => {
          console.log('error:', error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function getWaveInfo(waveObj) {
    const qs = queryString.stringify(waveObj);
    try {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_PREFIX}/bonzai/waves/getWave?${qs}`,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${user.token}` }
      })
        .then(
          response => {
            if (response.data && response.data.result) {
              let orders = response.data.result.orders;
              // console.log(orders)

              setWaveData(orders);
            }
          },
          error => {
            if (error.response.status === 401) {
              // alert('Token Expired.Please login again.');
              logout();
            }
          }
        )
        .catch(error => {
          console.log('error:', error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function filterByStatus(status) {
    let items = allWaves;
    if (status === '')
      setWaves(items);

    items = items.filter((item, index) => item.status.indexOf(status) >= 0);
    if (searchByWaveId !== '' && searchByWaveId !== null) {
      items = items.filter((item, index) => item.waveid.indexOf(searchByWaveId) >= 0);
    }
    setWaves(items);
  }

  function handleSearchSubmit() {
    if ((searchByWaveId === null || searchByWaveId === '') && (searchByStatus === null || searchByStatus === '')) {
      setWaves(allWaves);
    } else {
      let items = allWaves;
      if (searchByWaveId !== '' && searchByWaveId !== null) {
        items = items.filter((item, index) => item.waveid.indexOf(searchByWaveId) >= 0);
      }
      if (searchByStatus !== '') {
        if (items && items.length > 0) {
          items = items.filter((item, index) => item.status.indexOf(searchByStatus) >= 0);
        }
      }
      setWaves(items);
    }
  }

  function handleWaveDeleteCheck(waveId, checked) {
    let tempIds = [...aWaveDeleteIds];

    if (!checked) {
      const index = tempIds.indexOf(waveId);
      tempIds.splice(index, 1);
      setAWaveDeleteIds([...tempIds]);
    } else {
      tempIds.push(waveId);
      setAWaveDeleteIds([...tempIds]);
    }
  }

  function handleMultipleWaveDelete() {
    if (aWaveDeleteIds.length > 0) {
      setShowDeleteModal(true);
    }
  }

  useEffect(() => {
    getWaves();
  }, []);


  const exportWave = (waveId) => {
    console.log(waveData)

    let exportData = [];

    if (waveData)
      waveData.forEach(wd => {
        exportData.push({
          "Transfer Order Number": wd.Transfer_Order_Number,
          "Creation Date": wd.Creation_Date,
          "Storage Loaction": wd.Storage_location,
          "Source Storage Type": wd.Source_Storage_Type,
          "Source Storage Bin": wd.Source_Storage_Bin,
          "Material": wd.Material,
          "Material Description": wd.Material_Description,
          "Source target qty": wd.Source_target_qty,
          "Picked qty": wd.picked_qty,
          "Dest. storage Type": wd.Dest_Storage_Type,
          "Dest. Storage Bin": wd.Dest_Storage_Bin,
          "User": wd.User,
          "Group": wd.Group,

          /* "Locazione Picking": wd.Storage_location,
          "Quantità Presa": wd.picked_qty,
          "Quantità Richiesta": wd.Source_target_qty,
          "Codice Prodotto": wd.Material,
          "Delivery": wd.Dest_Storage_Bin, */
        });
      });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, `${waveId}.xlsx`);

  };


  return (
    <div className="position-relative">
      <div className="waves-container">
        <div className="mt-5">
          <div className="entry-heading pt-3">
            <img className="red-lines-img-l-waves" src="/images/logo-s.png" alt='' />
            <h1 className="  fw-bold d-flex align-items-center" style={{ color: '#0E3194' }}>
              Dettaglio Wave
              {/* <span className="red-lines-img-l-waves"> Dettaglio </span>&nbsp;Wave */}
            </h1>
            <h3 style={{ color: '#3A4472' }}>Stato Evasione Wave</h3>
          </div>
        </div>

        {downloadPDF ? (
          <>
            <WaveQRCode
              key="printComponent"
              waveId={downloadPDF}
              successCallback={() => {
                setDownloadPDF(null);
              }}
            />
          </>
        ) : null}

        <div className="h-100 w-100 position-relative  ">
          <div className="row">
            <div className="col d-flex">
              <div className="col-1  border border-gray7 rounded-start mr-5 text-center align-items-center justify-content-center d-flex" style={{ marginRight: '0.5rem' }}>
                <img
                  className="border-0 m-1"
                  src="/images/delete-icon.svg"
                  alt=""
                  style={{ width: '3.5rem', cursor: 'pointer' }}
                  onClick={() => {
                    handleMultipleWaveDelete();
                  }}
                />
              </div>
              <div className="col-11 d-flex py-4 border border-gray7 rounded-end" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="waveStatusFilter">
                  <select
                    className="form-select rounded-pill border-2 p-2 d-inline border border-blue5 text-purple"
                    aria-label="Default select example"
                    onChange={e => {
                      setSearchByStatus(e.target.value);
                      filterByStatus(e.target.value);
                      setAWaveDeleteIds([])
                    }}
                  >
                    <option value="" defaultValue="">Tutte</option>
                    <option value="default">Nuove</option>
                    <option value="progress">In Lavorazione</option>
                    {/* <option value="completed">Completate</option> */}
                    <option value="anomaly">Chiuse Con Anomalie</option>
                  </select>
                </div>
                <div className="waveNumberSearchbar">
                  <input
                    type="text"
                    className="form-control rounded-pill border-2 p-2 d-inline border border-blue5 text-purple"
                    aria-describedby="emailHelp"
                    placeholder="Wave Number"
                    onChange={e => {
                      setSearchByWaveId(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setSearchByWaveId(e.target.value);
                        handleSearchSubmit();
                      }
                    }}
                  />
                </div>
                <div className="waveSearchButton">
                  <button
                    className="btn btn-blue rounded-pill d-inline w-100 h-100"
                    onClick={() => {
                      handleSearchSubmit();
                    }}
                  >
                    CERCA
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='deleteAll' style={{
          display: waves.length > 0 ? "flex" : "none",
          marginLeft: "2%",
          marginTop: "30px",
          gap: "20px",
          color: "red",

        }}>
          <input
            className="form-check-input border-red custom-delete-input"
            type="checkbox"
            onChange={e => {

              if (e.target.checked)
                setAWaveDeleteIds([...waves.map(w => w.waveid)])
              else
                setAWaveDeleteIds([])
            }}

            checked={aWaveDeleteIds.length > 0 && aWaveDeleteIds.length === waves.length}
          />
          Seleziona Tutte

        </div>


        {/* Waves List */}

        <div className="accordion mb-5 mt-4" id="accordionPanelsStayOpenExample">
          {loading || waves === null ? (
            <h4 className="mt-4 text-center text-gray8">Caricamento...</h4>
          ) : waves.length === 0 ? (
            <h4 className="mt-4 text-center text-gray8">Nessun dato trovato.</h4>
          ) : (
            waves.map((row, index) => {
              return (
                <div className="accordion-item border-0" key={`waves-list-${row.waveid}`}>
                  <h2 className="accordion-header" id="panelsStayOpen-headingOne">

                    <div className="row justify-content-center align-items-center py-3">
                      <div className="col-1 align-items-center justify-content-center d-flex">
                        {/* Delete Check */}
                        <input
                          className="form-check-input mt-0 align-items-center form-check-input mt-0 align-items-center border-red custom-delete-input"
                          type="checkbox"
                          onChange={e => {
                            handleWaveDeleteCheck(row.waveid, e.target.checked);
                          }}
                          style={{ marginRight: '-1rem' }}
                          checked={aWaveDeleteIds.includes(row.waveid)}
                        />
                      </div>
                      <div className="col-3 d-inline float-left  align-items-center d-flex ">
                        <img className="border-0 imgWave" src={`/images/user-icon-${row.status}.svg`} alt="" />

                        <img
                          className="border-0 imgWave"
                          src="/images/qrcode.svg"
                          alt=""
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setDownloadPDF(row.waveid);
                          }}
                        />
                      </div>

                      {/* Button Wawe ID */}
                      <div className=" waveId">
                        <span
                          className="btn fw-bold py-4 rounded-pill bg-navy text-white w-100"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#panelsStayOpen-collapse-${index}`}
                          aria-expanded="false"
                          aria-controls={`panelsStayOpen-collapse-${index}`}
                          onClick={() => {
                            setVisibleWaveId(row.waveid);
                            getWaveInfo({
                              'company': user.company,
                              'waveid': row.waveid,
                              'productID': user.company,
                              'wavetype': waveType
                            });
                          }}
                        >
                          {row.waveid}
                        </span>
                      </div>

                      <div className="col-2 d-inline wavePercentage" >
                        <div className="btn fw-bold py-4 rounded-pill bg-navy text-white w-100 d-flex" style={{ cursor: "auto", justifyContent: "center" }}>
                          <img className="border-0 mx-2" src="/images/graph.svg" alt="" style={{ width: '2rem' }} /> {row.percentage ? row.percentage : 0}%
                        </div>

                      </div>


                    </div>
                  </h2>

                  <div id={`panelsStayOpen-collapse-${index}`} className="accordion-collapse collapse" aria-labelledby={`#panelsStayOpen-heading-${index}`}>
                    {visibleWaveId === row.waveid && (
                      <Fragment key={`wave-list-${index}`}>
                        <div className="accordion-body p-0 my-2">
                          <div className="card border-0 rounded-5" style={{ backgroundColor: '#cbe2f1' }}>
                            <div className="card-body" style={{ display: "flex", flexDirection: "column" }}>
                              <button className='export-excel'>
                                <img
                                  className=""
                                  src="/images/save-file.svg"
                                  alt="Export Excel"
                                  style={{ width: '2rem', cursor: 'pointer' }}
                                  onClick={() => exportWave(row.waveid)}
                                />
                              </button>

                              <div className="row mt-5 border-3 my-2 d-flex justify-content-evenly  wave-header">
                                <div className="col-2 text-center">
                                  <span className="btn px-2 py-3 rounded-pill  w-100 btn-1 ">Transfer Order</span> <br />
                                </div>
                                <div className="col-2 text-center">
                                  <span className="btn  px-2 py-3 rounded-pill  w-100 btn-2">Locazione Picking</span> <br />
                                </div>
                                <div className="col-2 text-center">
                                  <span className="btn px-2 py-3 rounded-pill  w-100 btn-3">Quantità Presa</span> <br />
                                </div>
                                <div className="col-2 text-center">
                                  <span className="btn px-2 py-3 rounded-pill  w-100 btn-3">Quantità Richiesta</span> <br />
                                </div>
                                <div className="col-2 text-center">
                                  <span className="btn px-2 py-3 rounded-pill  w-100 btn-4">Codice Prodotto</span> <br />
                                </div>
                                <div className="col-2 text-center">
                                  <span className="btn px-2 py-3 rounded-pill  w-100 btn-5">Delivery</span> <br />
                                </div>
                              </div>
                              <div className="" style={{ height: '20rem' }}>
                                <div className="h-100 overflow-y-scroll overflow-x-hidden">
                                  {waveData &&
                                    waveData.map((item, index) => {

                                      return (
                                        <div className="row border-bottom border-3 border-white d-flex justify-content-evenly wave-header" key={`${item.Transfer_Order_Number}-${index}`}
                                          style={{
                                            backgroundColor: getOrderColor(item.picked_qty, item.Source_target_qty, item.User),
                                            paddingBottom: "1rem !important",
                                            paddingTop: "1rem !important",
                                          }}>
                                          <div className="col-2 text-center">
                                            <span className=" px-2 py-2 w-100"> {item.Transfer_Order_Number} </span>
                                          </div>
                                          <div className="col-2 text-center">
                                            <span className=" px-2 py-2 w-100"> {item.Storage_location} </span>
                                          </div>
                                          <div className="col-2 text-center">
                                            <span className=" px-2 py-2 w-100"> {item.picked_qty} </span>
                                          </div>
                                          <div className="col-2 text-center">
                                            <span className=" px-2 py-2 w-100"> {item.Source_target_qty} </span>
                                          </div>
                                          <div className="col-2 text-center">
                                            <span className=" px-2 py-2 w-100" > {item.Material} </span>
                                          </div>
                                          <div className="col-2 text-center">
                                            <span className=" px-2 py-2 w-100"> {item.Dest_Storage_Bin} </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              );
            })
          )}

          {showDeleteModal && (
            <DeleteWaveModal
              aWaveDeleteIds={aWaveDeleteIds}
              waveType={waveType}
              _onSuccess={() => {
                setAWaveDeleteIds([]);
                setShowDeleteModal(false);
                getWaves();
              }}
              _onCancel={() => {
                setShowDeleteModal(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Waves;
